import {cva} from 'class-variance-authority';
import cn from 'classnames';

import type {Mode} from '@/components/base/types';
import {useIsTouchDevice} from '@/hooks/useIsTouchDevice';

interface TallCardCarouselBtnProps {
  idx: number;
  uniqueId: string;
  activeIndex: number;
  handleClickOnDot: (index: number) => void;
  mode?: Mode;
}

export default function TallCardCarouselBtn({
  idx,
  uniqueId,
  activeIndex,
  handleClickOnDot,
  mode = 'light',
}: TallCardCarouselBtnProps) {
  const isActive = activeIndex === idx;
  const isMobile = useIsTouchDevice();

  const buttonStyle = cva('active:bg-shade-50 h-2.5 w-2.5 rounded-full', {
    variants: {
      mode: {
        light: '',
        dark: '',
      },
      isActive: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        isActive: true,
        mode: 'light',
        className: 'bg-shade-70',
      },
      {
        isActive: false,
        mode: 'light',
        className: 'bg-shade-20',
      },
      {
        isActive: false,
        mode: 'dark',
        className: 'bg-shade-70',
      },
      {
        isActive: true,
        mode: 'dark',
        className: 'bg-shade-20',
      },
    ],
  });
  return (
    <button
      type="button"
      aria-controls={uniqueId}
      aria-label="Carousel button"
      onClick={() => handleClickOnDot(idx)}
      className={cn(buttonStyle({isActive, mode}), {
        'hover:bg-shade-50': !isMobile,
      })}
    />
  );
}
