import {cva} from 'class-variance-authority';

import Link, {type LinkProps} from '@/components/base/elements/Link/Link';
import HeadingGroup, {
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {twMerge} from '@/stylesheets/twMerge';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';

interface TallCardProps {
  headingGroup: HeadingGroupProps;
  link: LinkProps;
  image: ImageProps;
  mode?: 'light' | 'dark';
  className?: string;
  hasHoverEffect?: boolean;
}

export default function TallCard({
  headingGroup,
  link,
  image,
  mode = 'light',
  className,
  hasHoverEffect = false,
}: TallCardProps) {
  const styles = cva(
    'tall-card h-full flex flex-col rounded-2xl min-h-128 py-8',
    {
      variants: {
        hasHoverEffect: {
          true: 'hover:-translate-y-3 transition-transform duration-300',
          false: '',
        },
      },
    },
  );

  return (
    <div className={twMerge(styles({hasHoverEffect}), className)}>
      <div className="flex-grow px-4 md:px-8 mb-4">
        <HeadingGroup {...headingGroup} size="t3" mode={mode} />
      </div>
      <Image {...image} className={twMerge('w-full mb-4', image.className)} />
      <div className="px-4 md:px-8">
        <Link
          size="large"
          aria-label={link.text}
          {...link}
          mode={mode}
          className="long-link [&>span]:md:font-normal max-sm:text-sm"
        >
          {link.text}
        </Link>
      </div>
    </div>
  );
}
