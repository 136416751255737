import React, {useState, useRef, useEffect, useCallback} from 'react';

import useReducedMotion from '@/hooks/useReducedMotion';

// Largely adapted from https://github.com/jonathandion/react-tilt

interface TiltProps {
  options?: {
    reverse?: boolean;
    max?: number;
    perspective?: number;
    easing?: string;
    scale?: string;
    speed?: number;
    transition?: boolean;
    axis?: 'x' | 'y' | null;
    reset?: boolean;
  };
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const defaultSettings = {
  reverse: false,
  max: 10,
  perspective: 1000,
  easing: 'cubic-bezier(.03,.98,.52,.99)',
  scale: '1',
  speed: 1000,
  transition: true,
  axis: null,
  reset: true,
};

const Tilt: React.FC<TiltProps> = ({
  options = {},
  style,
  className,
  children,
}) => {
  const [tiltStyle, setTiltStyle] = useState<React.CSSProperties>({});
  const settings = {...defaultSettings, ...options};
  const tiltRef = useRef<HTMLDivElement>(null);
  const transitionTimeout = useRef<NodeJS.Timeout | null>(null);
  const updateCall = useRef<number | null>(null);
  const prefersReducedMotion = useReducedMotion();

  const updateElementPosition = useCallback(() => {
    if (tiltRef.current) {
      const rect = tiltRef.current.getBoundingClientRect();
      return {
        width: tiltRef.current.offsetWidth,
        height: tiltRef.current.offsetHeight,
        left: rect.left,
        top: rect.top,
      };
    }
    return {width: 0, height: 0, left: 0, top: 0};
  }, []);

  const setTransition = useCallback(() => {
    if (transitionTimeout.current) {
      clearTimeout(transitionTimeout.current);
    }

    setTiltStyle((prevStyle) => ({
      ...prevStyle,
      transition: `${settings.speed}ms ${settings.easing}`,
    }));

    transitionTimeout.current = setTimeout(() => {
      setTiltStyle((prevStyle) => ({
        ...prevStyle,
        transition: '',
      }));
    }, settings.speed);
  }, [settings.speed, settings.easing]);

  const reset = useCallback(() => {
    window.requestAnimationFrame(() => {
      setTiltStyle((prevStyle) => ({
        ...prevStyle,
        transform: `perspective(${settings.perspective}px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`,
      }));
    });
  }, [settings.perspective]);

  const getValues = useCallback(
    (
      e: React.MouseEvent,
      position: {width: number; height: number; left: number; top: number},
    ) => {
      const x = (e.clientX - position.left) / position.width;
      const y = (e.clientY - position.top) / position.height;
      const _x = Math.min(Math.max(x, 0), 1);
      const _y = Math.min(Math.max(y, 0), 1);
      const tiltX = parseFloat(
        (
          (settings.reverse ? -1 : 1) *
          (settings.max / 2 - _x * settings.max)
        ).toFixed(2),
      );
      const tiltY = parseFloat(
        (
          (settings.reverse ? -1 : 1) *
          (_y * settings.max - settings.max / 2)
        ).toFixed(2),
      );
      return {
        tiltX,
        tiltY,
      };
    },
    [settings.max, settings.reverse],
  );

  const update = useCallback(
    (e: React.MouseEvent) => {
      const position = updateElementPosition();
      const {tiltX, tiltY} = getValues(e, position);

      setTiltStyle({
        transform: `perspective(${settings.perspective}px) rotateX(${
          settings.axis === 'x' ? 0 : tiltY
        }deg) rotateY(${settings.axis === 'y' ? 0 : tiltX}deg) scale3d(${
          settings.scale
        }, ${settings.scale}, ${settings.scale})`,
      });
    },
    [
      getValues,
      settings.axis,
      settings.perspective,
      settings.scale,
      updateElementPosition,
    ],
  );

  const onMouseEnter = useCallback(() => {
    if (prefersReducedMotion) return;
    updateElementPosition();
    setTiltStyle({willChange: 'transform'});
    setTransition();
  }, [prefersReducedMotion, setTransition, updateElementPosition]);

  const onMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (prefersReducedMotion) return;
      e.persist();
      if (updateCall.current !== null) {
        window.cancelAnimationFrame(updateCall.current);
      }
      updateCall.current = window.requestAnimationFrame(() => update(e));
    },
    [prefersReducedMotion, update],
  );

  const onMouseLeave = useCallback(() => {
    if (prefersReducedMotion) return;
    setTransition();
    if (settings.reset) {
      reset();
    }
  }, [prefersReducedMotion, reset, setTransition, settings.reset]);

  useEffect(() => {
    return () => {
      if (transitionTimeout.current) {
        clearTimeout(transitionTimeout.current);
      }
      if (updateCall.current !== null) {
        window.cancelAnimationFrame(updateCall.current);
      }
    };
  }, []);

  return (
    <div
      ref={tiltRef}
      style={{...style, ...tiltStyle}}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default Tilt;
