import cn from 'classnames';
import {
  useState,
  createRef,
  useRef,
  useEffect,
  useCallback,
  useId,
} from 'react';

import {type HeadingGroupContentProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {useSiteData} from '@/hooks/useSiteData';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import type {AppKeysUnion} from '@/utils/UrlUtils';
import type {Mode} from '@/components/base/types';
import useAnimateIn from '@/components/shared/AnimateIn/useAnimateIn';
import {DEFAULTS as ANIMATION_DEFAULTS} from '@/components/shared/AnimateIn/constants';

import TallCardCarouselBtn from './TallCardCarouselBtn';
import TallCard from './TallCard';
import Tilt from './Tilt';

interface TallCardCarouselProps {
  counterMs?: number;
  cardSettings: {
    mode: Mode;
    image: {
      src: string;
      width: number;
      height: number;
    };
    link: {
      path: string;
      key?: AppKeysUnion;
      componentName?: string;
    };
  }[];
  cards: {
    headingGroup: HeadingGroupContentProps;
    link: {
      text: string;
    };
    image: {
      alt?: string;
    };
  }[];
  mode?: Mode;
}

export default function TallCardCarousel({
  cards,
  cardSettings,
  counterMs = 4000,
  mode = 'light',
}: TallCardCarouselProps) {
  const {getUrl} = useSiteData();
  const uniqueId = useId();
  const isMobileView = useMediaQuery('(max-width: 639px)');
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {animationClassName} = useAnimateIn({
    effect: 'custom',
    customAnimation: {
      static:
        'transition-opacity-transform ease-base duration-1000 opacity-0 translate-x-5',
      animated: 'translate-x-0 opacity-100',
    },
    ref: containerRef,
  });

  const cardRefs: any = useRef<null | HTMLDivElement>();
  cardRefs.current = cards
    ? cards.map(
        (_: any, i: number) =>
          cardRefs?.current?.[i] ?? createRef<null | HTMLDivElement>(),
      ) ?? [createRef<null | HTMLDivElement>()]
    : null;

  const loopTimer = useRef<ReturnType<typeof setInterval>>();
  const loopCallback = useCallback(() => {
    const isMovingBack = activeIndex === cards.length - 1;
    const newIndex = isMovingBack ? activeIndex - 1 : activeIndex + 1;
    return handleClickOnDot(newIndex);
  }, [cards.length, activeIndex]);

  useEffect(() => {
    if (isMobileView && isVisible) {
      loopTimer.current = setInterval(loopCallback, counterMs);
      return () => clearInterval(loopTimer.current);
    }
  }, [isMobileView, isVisible, counterMs, loopCallback]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        setIsVisible(entries[0].isIntersecting);
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            const index = cardRefs.current.findIndex(
              (cardRef: any) => cardRef.current === entry.target,
            );
            setActiveIndex(index);
          }
        });
      },
      {threshold: 1},
    );

    document
      .querySelectorAll('.tall-card-wrapper')
      .forEach((card) =>
        isMobileView ? observer.observe(card) : observer.unobserve(card),
      );
  }, [isMobileView]);

  const handleClickOnDot = (index = 0) => {
    const cardRef = cardRefs?.current?.[index] ?? null;
    if (!cardRef) return;
    cardRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const carouselNav = [] as JSX.Element[];
  const tallCards = [] as JSX.Element[];

  cards?.forEach(({headingGroup, image: {alt}, link: {text}}, idx) => {
    const {
      mode: cardMode,
      image,
      link: {path, key, componentName},
    } = cardSettings[idx];

    tallCards.push(
      <div
        ref={cardRefs.current[idx]}
        key={idx}
        className={cn(
          'tall-card-wrapper',
          {
            'snap-center transition-transform duration-400 ease-in-out':
              isMobileView,
          },
          animationClassName,
          ANIMATION_DEFAULTS.delaySequence[idx],
        )}
      >
        <Tilt options={{speed: 3000, max: 5}} className="h-full">
          <TallCard
            className={cn('max-sm:w-[300px]', {
              'bg-dark-blue': cardMode === 'dark',
              'bg-white': cardMode !== 'dark',
            })}
            headingGroup={{...headingGroup, headingAs: 'h3'}}
            image={{
              ...image,
              alt,
              className: '-translate-y-4',
            }}
            link={{
              text,
              href: getUrl(path, key),
              componentName,
            }}
            hasHoverEffect={false}
            mode={cardMode}
          />
        </Tilt>
      </div>,
    );

    isMobileView &&
      carouselNav.push(
        <TallCardCarouselBtn
          idx={idx}
          uniqueId={uniqueId}
          key={`dot-${idx}`}
          activeIndex={activeIndex}
          handleClickOnDot={handleClickOnDot}
          mode={mode}
        />,
      );
  });

  return (
    <div
      className="overflow-x-hidden sm:overflow-x-visible -mx-md sm:mx-0"
      ref={containerRef}
    >
      <div
        id={uniqueId}
        data-component-name="tall-card-carousel"
        className={cn('flex gap-x-2 sm:gap-x-6 px-md sm:px-0', {
          'overflow-x-scroll no-scrollbar snap-x snap-mandatory': isMobileView,
        })}
      >
        {tallCards}
      </div>
      {isMobileView && (
        <div className="flex justify-center mt-2xl h-2.5 gap-2.5">
          {carouselNav}
        </div>
      )}
    </div>
  );
}
